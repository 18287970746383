/**
 * 以下のブログ記事のコードを参考にした。その際、render propを使う方法の代わりに
 * useStaticQuery フックを使う方式に書き換えた
 *
 * @see https://takumon.com/simple-gatsby-image-wrapper
 */
import React from "react";
import useGatsbyImageSrc from "../hooks/useGatsbyImageSrc";

// 画像ファイルパスをプロパティに取るようなコンポーネントを定義
export default ({ filename, alt, notFoundFilename, ...props }) => {
  const [src] = useGatsbyImageSrc(filename, notFoundFilename);

  if (!src) {
    throw new Error(`画像ファイルが見つかりません: ${filename}`);
  }

  // コンソールに alt が設定されていないという旨のエラーが出てきてしまうので、
  // props とは別に alt を明示的に指定する
  return <img src={src} alt={alt || ""} {...props} />;
};
