export const navLinks = [
  {
    label: "ホーム",
    to: "/",
  },
  {
    label: "企業情報",
    to: "/about/",
  },
  {
    label: "サービス",
    to: "/services/",
  },
  {
    label: "採用情報",
    to: "https://portal.socialdog.jp/recruit",
    external: true,
  },
  {
    label: "ニュース",
    to: "/news/",
  },
  {
    label: "お問い合わせ",
    to: "/contact/",
  },
  {
    label: "情報ポータル",
    to: "https://portal.socialdog.jp/",
    external: true,
    onlyInFooter: true,
  },
];
