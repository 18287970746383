import React from "react";
import PropTypes from "prop-types";
import styled, { ThemeProvider, css } from "styled-components";
import "../webfonts/webfonts.css";
import theme from "../utils/theme";
import "ress/ress.css";
import GlobalStyle from "../components/GlobalStyle";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Layout = ({ location, children }) => {
  const english = location.pathname.indexOf("/en") === 0;

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header english={english} />
      <Main>{children}</Main>
      <Footer english={english} />
    </ThemeProvider>
  );
};

const Main = styled.main`
  & > *:not(:first-child) {
    ${Array.from({ length: 200 }, (_, k) => k).map(
      i => css`
        @media (prefers-reduced-motion: no-preference) {
          &:nth-child(${i + 2}) {
            animation: 0.75s ease appearing${i} ${0.1 * i}s both;
          }
        }

        @keyframes appearing${i} {
          from {
            opacity: 0;
            transform: translateY(${50 + i * 25}px);
          }

          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `
    )}
  }
`;

Layout.propTypes = {
  english: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  english: false,
};

export default Layout;
