import { Link } from "gatsby";
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import Container from "./Container";
import ExternalLink from "./ExternalLink";
import { navLinks } from "../utils/links";
import { max_lg, min_lg } from "../utils/mediaQuery";
import { header, sidebar, toggle } from "../utils/zIndex";

export const headerHeight = "80px";

const Header = ({ english }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggle = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <StyledHeader>
      <HeaderContent large>
        <BrandLink to="/">
          <BrandLinkImage
            src="/socialdog_inc_logo_white.svg"
            width="939"
            height="219"
            alt="SocialDog, Inc."
          />
        </BrandLink>
        <Toggle onClick={toggle} aria-label="メニューを開閉">
          <FontAwesomeIcon fixedWidth icon={isSidebarOpen ? faTimes : faBars} />
        </Toggle>
        <Nav isSidebarOpen={isSidebarOpen} onClick={toggle}>
          {english ? (
            <NavLink isSidebarOpen={isSidebarOpen} to="/">
              Japanese
            </NavLink>
          ) : (
            navLinks.map((item, index) => {
              if (item.onlyInFooter) {
                return null;
              }

              return (
                <NavLink
                  style={
                    isSidebarOpen
                      ? { "--navlink-index": index }
                      : { transition: "none" }
                  }
                  isSidebarOpen={isSidebarOpen}
                  isExternal={item.external}
                  to={item.to}
                  activeClassName="active"
                  key={item.label}
                >
                  {item.label}
                </NavLink>
              );
            })
          )}
        </Nav>
      </HeaderContent>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  background: transparent;
  height: ${headerHeight};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: ${header};
`;

const HeaderContent = styled(Container)`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 0;
`;

const linkStyles = css`
  color: ${({ theme }) => theme.colors.normalOnMain};
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0 20px;
`;

const BrandLink = styled(Link)`
  ${linkStyles}
  font-size: 24px;
`;

const BrandLinkImage = styled.img`
  display: block;
  height: 36px;
  width: auto;
`;

const Toggle = styled.button`
  color: ${({ theme }) => theme.colors.normalOnMain};
  appearance: none;
  position: fixed;
  display: flex;
  z-index: ${toggle};
  height: 50px;
  width: 50px;
  top: 15px;
  right: 15px;
  background: ${({ theme }) => theme.colors.main};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-size: 20px;

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.focus};
  }

  ${min_lg(css`
    display: none;
  `)}
`;

const NavElement = ({ isSidebarOpen, ...props }) => <nav {...props} />;

const Nav = styled(NavElement)`
  display: flex;

  ${max_lg(css`
    @media (prefers-reduced-motion: no-preference) {
      transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1) visibility,
        0.4s cubic-bezier(0.075, 0.82, 0.165, 1) transform;
    }
    transform-origin: left;
    ${({ isSidebarOpen }) =>
      isSidebarOpen
        ? css`
            visibility: visible;
            transform: translate(0);
          `
        : css`
            visibility: hidden;
            transform: translate(100%);
          `}

    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${sidebar};
    background: ${({ theme }) => theme.colors.main};
    padding: 80px 19px 24px;
  `)}
`;

const LinkElement = ({ isSidebarOpen, isExternal, ...props }) =>
  isExternal ? <ExternalLink {...props} /> : <Link {...props} />;

const NavLink = styled(LinkElement)`
  ${linkStyles}
  &.active {
    color: ${({ theme }) => theme.colors.mutedOnMain};
  }

  ${max_lg(css`
    height: 64px;
    font-size: 16px;
    justify-content: flex-end;
    text-align: right;
    @media (prefers-reduced-motion: no-preference) {
      transition: 0.6s ease opacity, 0.6s ease transform;
      transition-delay: calc(var(--navlink-index) * 0.075s + 0.1s);
    }

    ${({ isSidebarOpen }) =>
      isSidebarOpen
        ? css`
            opacity: 1;
            transform: translateY(0);
            padding: 10px;
          `
        : css`
            opacity: 0;
            transform: translateY(10%);
          `}
  `)}

  [data-icon] {
    margin-left: 3px;
  }
`;

Header.propTypes = {
  english: PropTypes.bool,
};

Header.defaultProps = {
  english: false,
};

export default Header;
