import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = ({ large, className, children }) => (
  <StyledContainer large={large} className={className}>
    {children}
  </StyledContainer>
);

const StyledContainer = styled.div`
  width: 100%;
  max-width: ${({ large }) => (large ? "1280px" : "960px")};
  margin: 0 auto;
  padding: 0 20px;
`;

Container.propTypes = {
  large: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Container.defaultProps = {
  large: false,
  className: "",
};

export default Container;
