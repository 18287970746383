import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import Container from "./Container";
import Row from "./Row";
import Column from "./Column";
import Image from "./Image";
import Card from "./Card";
import ExternalLink from "./ExternalLink";
import { navLinks } from "../utils/links";
import { max_md } from "../utils/mediaQuery";

const Footer = ({ english }) => (
  <StyledFooter>
    <Container>
      <Row>
        <Column col={4} colSp={12}>
          <TitleLink to="/">SocialDog, Inc.</TitleLink>
          <Copyright>©SocialDog, Inc.</Copyright>
          <Certification>
            <CertificationImagesContainer>
              <CertificationImage
                filename="bv_iso27001.png"
                alt="ISO 27001 BUREAU VERITAS Certification"
                width="414"
                height="193"
                displayWidth="138"
              />
              <CertificationImage
                filename="isms_ac.png"
                alt="ISMS-AC"
                width="171"
                height="222"
                displayWidth="57"
              />
            </CertificationImagesContainer>
            {!english && (
              <CertificationDescription>
                株式会社SocialDogは情報セキュリティマネジメントシステム(ISMS)の認証(ISO/IEC
                27001:2022, JIS Q 27001:2023)を取得しました。
              </CertificationDescription>
            )}
          </Certification>
        </Column>
        {english ? (
          <Column>
            <Navs>
              <ChangeLanguageLink to="/">Japanese</ChangeLanguageLink>
            </Navs>
          </Column>
        ) : (
          <>
            <Column col={4} colSp={6}>
              <Navs>
                {navLinks.map(item => (
                  <NavLink
                    isExternal={item.external}
                    to={item.to}
                    key={item.label}
                  >
                    {item.label}
                  </NavLink>
                ))}
              </Navs>
            </Column>
            <Column col={4} colSp={6}>
              <Navs>
                <NavHeading>サービス</NavHeading>
                <NavServiceLink
                  href="https://social-dog.net/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SocialDog
                </NavServiceLink>
                <ChangeLanguageLink to="/en/">English</ChangeLanguageLink>
                <NavLink to="/privacy/">プライバシーポリシー</NavLink>
                <NavLink to="/information-security/">
                  情報セキュリティポリシー
                </NavLink>
              </Navs>
            </Column>
          </>
        )}
      </Row>
    </Container>
  </StyledFooter>
);

const StyledFooter = styled.footer`
  padding: 80px 0;

  ${max_md(css`
    padding: 40px 0;
  `)}
`;

const LINK_Y_PADDING = "10px";

const Navs = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: -${LINK_Y_PADDING} 0;

  ${max_md(css`
    margin: 0;
  `)}
`;

const TitleLink = styled(Link)`
  display: block;
  font-size: 24px;
  margin: 0 0 8px;
  text-decoration: none;
  line-height: 1;
`;

const Copyright = styled.p`
  color: ${({ theme }) => theme.colors.muted};
  font-size: 14px;
  line-height: 1;
  margin: 0 0 20px;
`;

const Certification = styled(Card)`
  margin: 0 0 20px;
  padding: 12px;
`;

const CertificationImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CertificationImageComponent = ({ displayWidth, ...props }) => {
  return <Image {...props} />;
};

const CertificationImage = styled(CertificationImageComponent)`
  display: block;
  width: ${({ displayWidth }) => displayWidth}px;
  height: auto;
`;

const CertificationDescription = styled.p`
  color: ${({ theme }) => theme.colors.muted};
  margin: 0;
  font-size: 12px;
  margin: 10px 0 0;
`;

const NavLinkStyle = css`
  color: ${({ theme }) => theme.colors.normal};
  display: block;
  text-decoration: none;

  margin: 0;
  padding: ${LINK_Y_PADDING} 0;
  padding-left: 10px;

  ${max_md(css`
    padding-left: 0;
  `)}

  &:hover {
    text-decoration: underline;
  }
`;

const LinkElement = ({ isExternal, ...props }) =>
  isExternal ? <ExternalLink {...props} /> : <Link {...props} />;

const NavLink = styled(LinkElement)`
  ${NavLinkStyle}

  [data-icon] {
    margin-left: 3px;
  }
`;

const NavHeading = styled.div`
  ${NavLinkStyle}
  color: ${({ theme }) => theme.colors.muted};
  font-weight: bold;

  &:hover {
    text-decoration: none;
  }
`;

const NavServiceLink = styled.a`
  ${NavLinkStyle}
  padding-left: 30px;

  ${max_md(css`
    padding-left: 10px;
  `)}
`;

const ChangeLanguageLink = styled(Link)`
  ${NavLinkStyle}
`;

Footer.propTypes = {
  english: PropTypes.bool,
};

Footer.defaultProps = {
  english: false,
};

export default Footer;
