import { css } from "styled-components";

export const max_md = style => css`
  @media (max-width: 767.98px) {
    ${style};
  }
`;

export const min_md = style => css`
  @media (min-width: 768px) {
    ${style};
  }
`;

export const max_lg = style => css`
  @media (max-width: 991.98px) {
    ${style};
  }
`;

export const min_lg = style => css`
  @media (min-width: 992px) {
    ${style};
  }
`;

export const max_xl = style => css`
  @media (max-width: 1199.98px) {
    ${style};
  }
`;

export const min_xl = style => css`
  @media (min-width: 1200px) {
    ${style};
  }
`;
