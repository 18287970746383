import React from "react";
import styled, { css } from "styled-components";
import { min_lg } from "../utils/mediaQuery";
import useColumnGutter from "../hooks/useColumnGutter";

export default ({ col, colSp, children }) => {
  const { gutter } = useColumnGutter();
  return (
    <Column col={col} colSp={colSp || 12} gutter={gutter}>
      {children}
    </Column>
  );
};

const Column = styled.div`
  padding: 0 ${({ gutter }) => gutter};
  ${({ colSp }) =>
    css`
      flex: 0 0 ${100 * (colSp / 12)}%;
      max-width: ${100 * (colSp / 12)}%;
    `}

  ${min_lg(
    css`
      ${({ col }) =>
        col
          ? css`
              flex: 0 0 ${100 * (col / 12)}%;
              max-width: ${100 * (col / 12)}%;
            `
          : css`
              flex: 1;
            `}
    `
  )}
`;
