/*
## このファイルに書くもの
- z-index
  - 意図しない要素同士の重なり合いが発生しないようにするため
*/

export const pageHeaderContent = 8300;
export const header = 9990;
export const sidebar = 9995;
export const toggle = 9999;
