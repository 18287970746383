import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

const ExternalLink = props => {
  return (
    <a href={props.to} target="_blank" rel="noopener noreferrer" {...props}>
      {props.children}
      <FontAwesomeIcon icon={faExternalLinkAlt} size="xs" />
    </a>
  );
};

export default ExternalLink;
