import { useStaticQuery, graphql } from "gatsby";

export default (filename, notFoundFilename) => {
  // ページじゃないコンポーネントでもGraphQLが使えるように
  // StaticQueryタグを使う
  // GraphQLのクエリ引数には何も指定しない！
  const data = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(maxWidth: 2000) {
                src
              }
            }
          }
        }
      }
    }
  `);

  let edge = data.images.edges.find(n => {
    return n.node.relativePath === filename;
  });

  if (!edge && notFoundFilename) {
    edge = data.images.edges.find(n => {
      return n.node.relativePath === notFoundFilename;
    });
  }

  const src = edge ? edge.node.childImageSharp.fluid.src : null;

  return [src];
};
