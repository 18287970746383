import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
::selection {
  background: ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.normalOnMain};
}

body {
  font: 400 14px/1.7 Gordita, sans-serif;
  background: ${({ theme }) => theme.colors.base};
}

a {
  color: ${({ theme }) => theme.colors.main};

  &:hover {
    text-decoration: none;
  }
}

ul {
  padding-left: 16px;
}

li {
  margin: 0 0 5px;

  &:last-child {
    margin-bottom: 0;
  }
}
`;
