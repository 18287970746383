import React from "react";
import styled, { css } from "styled-components";
import { ColumnGutterProvider } from "../hooks/useColumnGutter";

export default ({ justifyContent, alignItems, gutter, children }) => {
  const defaultGutter = "15px";
  return (
    <ColumnGutterProvider gutter={gutter || defaultGutter}>
      <Row
        justifyContent={justifyContent}
        alignItems={alignItems}
        gutter={gutter || defaultGutter}
      >
        {children}
      </Row>
    </ColumnGutterProvider>
  );
};

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 ${({ gutter }) => `-${gutter}`};
  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}
  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems};
    `}
`;
