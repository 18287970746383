/*

## このファイルに書くもの

- 色
  - 全ページで共通した色を使えるようにするため
  - ダークモード対応のため


## baseSub と normalOnMain は同じ白なのに別カラー扱いにしている理由

現状のライトモードでは同じ色となるが、ダークモードでは異なる色になる可能性があるため。
ダークモードをデザインした際も、mainはおそらく現状とほぼ同じ色を使うことになる。
そういった場合、normalOnMain は引き続き白色である必要がある。しかし baseSub はそうでない。
ダークモードにした場合、base の色定義は間違いなく黒に近い色になると思われる。
そのため、ダークモードにおいて baseSub の色定義を引き続き白色にしてしまうと、目立ちすぎてしまう。
ライトモードにおいては baseSub は base においてほんのり目立つ程度になっている。
ライトモードとダークモードにおいて baseSub の見え方が同じになるように調整する必要がある。
つまり、baseSub と normalOnMain は、ダークモードにおいて異なる色にする必要がある。
よって、以下の色定義では、baseSub と normalOnMain は同じ白なのに別カラー扱いにしている。
*/

const colors = {
  focus: "rgb(158, 197, 246)", // フォーカス枠の色
  main: "#1769E2", // SocialDog Brand Primary
  mainLight: "#E1F4FF", // 薄い色
  normal: "#0F1D28", // 本文の文字色
  base: "#F8FAFC", // 背景色
  baseSub: "#FFFFFF", // 背景色。背景色が base の要素内で、特に目立たせたい要素に適用する色
  baseSecondary: "#F2F5F9", // 少し濃いめの背景色
  socialdog: "#1769E2",
  whotwi: "#4990E2", // whotwi の色
  muted: "#4E5D6F", // 灰色、本文サブカラー
  border: "#D6DFEA", // 灰色、ボーダー
  normalOnMain: "#FFFFFF", // main カラー上に配置する normal カラー
  mutedOnMain: "rgba(255, 255, 255, 0.8)", // main カラー上に配置する muted カラー
  inputBackground: "#EAEFF6", // フォームコントロールのデフォルト背景色
};

export default {
  colors,
};
