import React, { useContext } from "react";

const ColumnGutterContext = React.createContext();

export const ColumnGutterProvider = ({ gutter, children }) => (
  <ColumnGutterContext.Provider value={{ gutter }}>
    {children}
  </ColumnGutterContext.Provider>
);

export default () => ({
  gutter: useContext(ColumnGutterContext).gutter,
});
