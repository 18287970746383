import React from "react";
import styled from "styled-components";

export default props => <Card {...props} />;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.normalOnMain};
  padding: 24px;
  border-radius: 8px;
  text-decoration: none;
  width: 100%;
`;
